import styled from "styled-components";
import { Link } from "react-router-dom";

export const FooterSection = styled("footer")`
  background: rgb(0, 0, 0);
  padding: 2.5rem 0;
`;

export const Title = styled("h4")`
  font-size: 22px;
  text-transform: capitalize;
  color: #37A660;

  @media screen and (max-width: 414px) {
    padding: 1.5rem 0;
  }
`;

export const NavLink = styled(Link)`
  display: block;
  font-size: 1rem;
  margin-bottom: 0.625rem;
  transition: all 0.2s ease-in-out;

  &:hover,
  &:active,
  &:focus {
    color: #37A660;
  }
`;

export const Extra = styled("section")`
  background: rgb(0, 0, 0);
  position: relative;
  width: 100%;
  margin-right: auto;
  margin-left: auto;
  padding-bottom: 2rem;
`;

export const DividerLine = styled("div")`
  width: 100%;
  height: 2px;
  background: linear-gradient(90deg, rgb(93, 224, 230), rgb(55, 166, 96));
`;

export const LogoContainer = styled("div")`
  display: flex;
  position: relative;
`;

export const Para = styled("div")`
  color: #37A660;
  font-size: 14px;
  width: 70%;
`;

export const Large = styled(Link)`
  font-size: 16px;
  color: #FFFFFF;
  cursor: pointer;
  transition: all 0.2s ease-in-out;
  text-transform: capitalize;
  line-height: 24px;
  display: block;
  margin-bottom: 0.625rem;
  transition: all 0.1s ease-in-out;
  max-width: max-content;

  &:hover {
    color: rgb(255, 255, 255);
    text-underline-position: under;
    text-decoration: underline;
  }
`;


export const Chat = styled("p")`
  color: transparent; /* Set text color to transparent */
  max-width: fit-content;
  cursor: pointer;
  margin-top: 1rem;
  background: linear-gradient(90deg, rgb(93, 224, 230), rgb(55, 166, 96)); /* Gradient background for text */
  background-clip: text; /* Clip the background to the text */
  -webkit-background-clip: text; /* Webkit prefix for Safari and Chrome */
  -webkit-text-fill-color: transparent; /* Make the text fill transparent to show the gradient */
  position: relative; /* Position relative for pseudo-element */
  transition: all 0.1s ease-in-out;

  &::after {
    content: ''; /* Empty content for the pseudo-element */
    display: block;
    position: absolute;
    left: 0;
    bottom: -2px; /* Position the underline below the text */
    width: 100%;
    height: 2px; /* Height of the underline */
    background: linear-gradient(90deg, rgb(93, 224, 230), rgb(55, 166, 96)); /* Gradient for underline */
    transition: opacity 0.1s ease-in-out;
    opacity: 0; /* Start with the underline invisible */
  }

  &:hover::after {
    opacity: 1; /* Show the underline on hover */
  }
`;



export const Empty = styled("div")`
  position: relative;
  height: 53px;
`;

export const FooterContainer = styled("div")`
  max-width: 510px;
  width: 100%;
  display: flex;
  justify-content: space-between;
  text-align: center;
  align-items: center;
  transition: all 0.1s ease-in-out;

  a {
    &:hover,
    &:active,
    &:focus {
      -webkit-transform: scale(1.1);
      -ms-transform: scale(1.1);
      transform: scale(1.1);
    }
  }

  @media screen and (max-width: 769px) {
    width: auto;

    a:not(:last-child) {
      display: none;
    }
  }

  div {
    cursor: pointer;
    margin-right: 15px;
    width: 25px;
    height: 25px;

    &:hover {
      fill: rgb(255, 130, 92);
    }
  }
`;

export const Language = styled("h4")`
  font-size: 22px;
  text-transform: capitalize;
  color: #000000;

  @media screen and (max-width: 414px) {
    padding: 1.5rem 0;
  }
`;

export const Label = styled("label")`
  font-size: 22px;
  text-transform: capitalize;
  color: #ffffff;
  display: block;
  margin-bottom: 2rem;
  font-family: "Quicksand Regular", serif;

  @media screen and (max-width: 414px) {
    padding: 1.5rem 0;
    margin-bottom: 1rem;
  }
`;

export const LanguageSwitch = styled("div")`
  cursor: pointer;
  transition: all 0.1s ease-in-out;

  &:hover,
  &:active,
  &:focus {
    -webkit-transform: scale(1.1);
    -ms-transform: scale(1.1);
    transform: scale(1.1);
  }
`;

export const LanguageSwitchContainer = styled("div")`
  display: flex;
  justify-content: space-between;
  width: 85px;
`;
