import { Row, Col } from "antd";
import { SvgIcon } from "../../common/SvgIcon";
import Container from "../../common/Container";

import {
  FooterSection,
  Title,
  NavLink,
  Extra,
  LogoContainer,
  Para,
  Large,
  Chat,
  Empty,
  FooterContainer,
  Language,
  Label,
  DividerLine, // Import the DividerLine
} from "./styles";

interface SocialLinkProps {
  href: string;
  src: string;
}

const Footer = () => {

  // const SocialLink = ({ href, src }: SocialLinkProps) => {
  //   return (
  //     <a
  //       href={href}
  //       target="_blank"
  //       rel="noopener noreferrer"
  //       key={src}
  //       aria-label={src}
  //     >
  //       <SvgIcon src={src} width="25px" height="25px" />
  //     </a>
  //   );
  // };

  return (
    <>
      <DividerLine /> {/* Divider line added here */}
      <FooterSection>
        <Container>
          <Row justify="center" align="middle">
            <Col>
              <a href="mailto:excelsior.companyuse@gmail.com">
                <Chat>{`Contact Us Today!`}</Chat>
              </a>
            </Col>
          </Row>
          <Extra>
          </Extra>
          <Row justify="center" align="middle">
            <Col style={{ margin: '0 4rem' }}>
              <Large to="/">{"Privacy"}</Large>
            </Col>
            <Col style={{ margin: '0 4rem' }}>
              <Large to="/">{"Terms and Conditions"}</Large>
            </Col>
            <Col style={{ margin: '0 4rem' }}>
              <Large to="/">{"Cookie Policy"}</Large>
            </Col>
            <Col style={{ margin: '0 4rem' }}>
              <Large to="/">{"Customer Support"}</Large>
            </Col>
          </Row>
        </Container>
      </FooterSection>
      <Extra>
      </Extra>
    </>
  );
};

export default Footer;
